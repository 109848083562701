import React, { Suspense } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { ReactQueryDevtools } from "react-query/devtools";
import Loading from "src/components/Loading";
import GlobalErrorBoundary from "src/utils/sentry/GlobalErrorBoundary";
import reactLazyWithRetries from "src/utils/reactLazyWithRetries";
import { useDocumentTitle } from "src/utils/hooks";
import "src/scss/style.scss";
import { Intercom, Mixpanel } from "src/utils/analytics";
import ProtectedRoute from "src/components/ProtectedRoute";
import InjectAxiosInterceptors from "src/components/InjectAxiosInterceptors";
import { PortalVariantProvider } from "src/hooks/usePortalVariant";
import { ToastHost } from "src/components/Toast/ToastHost";
import { ViewportInfoProvider } from "src/hooks/useViewportInfo";
import { CustomerAuthState, useAuth, AuthProvider } from "./AuthProvider";

Intercom.initialise();
Intercom.boot();

Mixpanel.initialise();

const Login = reactLazyWithRetries(
  () =>
    import(/* webpackChunkName: "Login" */ "src/apps/customer/screens/Login")
);

const Signup = reactLazyWithRetries(
  () =>
    import(/* webpackChunkName: "Login" */ "src/apps/customer/screens/signup")
);

const ErrorFallback = reactLazyWithRetries(
  () => import(/* webpackChunkName: "Login" */ "src/screens/ErrorFallback")
);

const LoggedIn = reactLazyWithRetries(
  () =>
    import(/* webpackChunkName: "Login" */ "src/apps/customer/screens/LoggedIn")
);

const ResetPin = reactLazyWithRetries(
  () =>
    import(
      /* webpackChunkName: "ResetPin" */ "src/apps/customer/screens/ResetPin"
    )
);

const Routes = () => {
  const { isAuthenticated } = useAuth() as CustomerAuthState;
  return (
    <Switch>
      <Route exact path="/500" component={ErrorFallback} />
      <Route exact path="/resetpin" component={ResetPin} />
      <Route exact path="/signup" component={Signup} />
      <ProtectedRoute
        exact={true}
        path="/login"
        component={Login}
        isAllowed={!isAuthenticated}
        redirectToIfNotAllowed="/bookkeeping"
      />
      <ProtectedRoute
        path="/"
        component={LoggedIn}
        isAllowed={isAuthenticated}
        redirectToIfNotAllowed="/login"
        includeRedirectQueryParam={true}
      />
    </Switch>
  );
};

export type CustomerAppProps = {
  queryClient: QueryClient;
};

const CustomerApp: React.FC<CustomerAppProps> = ({
  queryClient,
}: {
  queryClient: QueryClient;
}) => {
  useDocumentTitle();

  return (
    <ViewportInfoProvider>
      <PortalVariantProvider variant="customer">
        <QueryClientProvider client={queryClient}>
          <BrowserRouter>
            <GlobalErrorBoundary>
              <AuthProvider>
                <InjectAxiosInterceptors useAuthHook={useAuth} />
                <Suspense fallback={<Loading />}>
                  <Routes />
                </Suspense>
                <ToastHost />
              </AuthProvider>
            </GlobalErrorBoundary>
          </BrowserRouter>
          {process.env.NODE_ENV === "development" && <ReactQueryDevtools />}
        </QueryClientProvider>
      </PortalVariantProvider>
    </ViewportInfoProvider>
  );
};

export default CustomerApp;
