import api from "src/utils/api";
import { variant } from "src/config";
import { CustomerMeData } from "src/apps/customer/AuthProvider";
import { AccountantMeData } from "src/apps/accountant/AuthProvider";

const ACCOUNTANT_ME_ENDPOINT = "/accountant_portal_api/advisors/me/";
const CUSTOMER_ME_ENDPOINT = "/accountant_portal_api/me/";

export const ME_ENDPOINT =
  variant === "accountant" ? ACCOUNTANT_ME_ENDPOINT : CUSTOMER_ME_ENDPOINT;

type CustomerSignupBeginProps = {
  username: string;
  passcode: string;
  firstName: string;
  lastName: string;
  email: string;
};

type CustomerLoginBeginProps = {
  phoneNumber: string;
  passCode: string;
};

export const customerLoginBegin = ({
  phoneNumber,
  passCode,
}: CustomerLoginBeginProps) =>
  api.apiClient.post("/accountant_portal_api/auth/customer/login-begin/", {
    phone_number: phoneNumber,
    passcode: passCode,
  });

export const customerSignUpBegin = ({
  username,
  passcode,
  firstName,
  lastName,
  email,
}: CustomerSignupBeginProps) =>
  api.apiClient.post("/accountant_portal_api/onboard/signup/", {
    passcode: passcode,
    username: username,
    first_name: firstName,
    last_name: lastName,
    email: email,
  });

export type CustomerLoginSubmitProps = {
  phone_number: string;
  passcode: string;
  code: string;
};

export const customerLoginSubmit = (data: CustomerLoginSubmitProps) =>
  api.apiClient.post(
    "/accountant_portal_api/auth/customer/login-submit/",
    data
  );

export const CustomerSignUpTwoFactor = (data: CustomerLoginSubmitProps) =>
  api.apiClient.post(
    "/accountant_portal_api/onboard/signup-verification/",
    data
  );

export type AccountantLoginProps = {
  username: string;
  password: string;
};

export const accountantLogin = (data: AccountantLoginProps) =>
  api.apiClient.post("/accountant_portal_api/login/", data);

export const getCustomerMe = async () => {
  const response = await api.apiClient.get<CustomerMeData>(
    CUSTOMER_ME_ENDPOINT
  );

  return response.data;
};

export const getAccountantMe = async () => {
  const response = await api.apiClient.get<AccountantMeData>(
    ACCOUNTANT_ME_ENDPOINT
  );

  return response.data;
};

export const logout = () =>
  api.apiClient.post("/accountant_portal_api/logout/");
